import React from "react";

import Menu from "./Menu";
// import MenuOutline from "../icons/MenuOutline";
import EsinliOutline from "../icons/EsinliOutline";

import {Link} from "gatsby";
// import {Button, Space} from "antd-mobile";
import {Space} from "antd-mobile";

const NavBar = ({mode = 'light'}) => {

    // const color = mode === 'light' ? 'black' : 'white'

    const [menuOpen, setMenuOpen] = React.useState(false)

    return (
        <>
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
            <div style={{
                top: 0,
                left: 0,
                right: 0,
                zIndex: 999,
                position: 'fixed',
                borderBottom: '1px solid rgb(209, 213, 219)',
                WebkitBackdropFilter: 'saturate(1.8) blur(20px)',
                background: mode === 'light' ? 'rgba(255, 255, 255, .8)' : 'rgba(17, 24, 39,0.8)'
            }}>
                <div className="adm-nav-bar"
                     style={{background: "none"}}>
                    <div className="adm-nav-bar-left">
                        <div className="adm-nav-bar-back">
                            <Link to={'/'} style={{color: 'rgb(17, 24, 39)'}}>
                                <Space align="end">
                                    <EsinliOutline size={20}/><strong style={{fontSize: 18}}>Esinli Capital</strong>
                                </Space>
                            </Link>
                        </div>
                    </div>
                    <div className="adm-nav-bar-right">
                        <div className="adm-nav-bar-forward">
                            <Space align="center" style={{'--gap': '18px'}}>
                                {/*<Link to="/id/account"*/}
                                {/*      className="adm-button adm-button-primary adm-button-shape-rounded">*/}
                                {/*    Start now*/}
                                {/*</Link>*/}
                                <Link to="/id/sign-in"
                                      className="adm-button adm-button-primary adm-button-fill-none visible-large">
                                    Sign in
                                </Link>
                                {/*<Button fill="none"*/}
                                {/*        color="black"*/}
                                {/*        shape="rounded"*/}
                                {/*        aria-label="Menu"*/}
                                {/*        className="hidden-large"*/}
                                {/*        style={{height: 27, padding: 0}}*/}
                                {/*        onClick={() => setMenuOpen(true)}>*/}
                                {/*    <MenuOutline color={color} size={18}/>*/}
                                {/*</Button>*/}
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NavBar;
