import React from 'react';
import {CognitoUserPool} from "amazon-cognito-identity-js";

const AuthContext = React.createContext()

const userPool = new CognitoUserPool({
    UserPoolId: 'eu-west-1_PBlgxjuGR', // Your user pool id here
    ClientId: '2vnoamb56al6ei01frfe8aqfuo', // Your client id here
});

const AuthProvider = ({children}) => {

    const [error, setError] = React.useState(null)
    const [user, setUser] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const confirmRegistration = async (code) => {
        user.confirmRegistration(
            code,
            true,
            (err, result) => {
                if (err) {
                    setError(err.message);
                    return;
                }
                console.log('call result: ' + result);
            })
    }

    const signUp = async (email, password) => {
        setLoading(true)
        userPool.signUp(
            email,
            password,
            [],
            null,
            (err, result) => {
                if (err) {
                    setError(err.message);
                } else {
                    setUser(result.user)
                }
                setLoading(false)
            });
    }

    return (
        <AuthContext.Provider value={{
            user,
            error,
            loading,
            signUp,
            confirmRegistration
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};

export {AuthProvider, useAuth};
