import React from "react";
import { Link } from "gatsby";
import LinkedinOutline from "../icons/LinkedinOutline";
import EsinliOutline from "../icons/EsinliOutline";

// Custom styled footer header to match the landing page design
const FooterHeader = ({ children }) => (
    <div style={{
        fontFamily: 'Source Sans Pro, Arial, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        marginBottom: '24px',
        color: '#CCCCCC',
        textTransform: 'uppercase'
    }}>
        {children}
    </div>
);

// Custom styled footer link to match the landing page design
const FooterLink = ({ to, children, external = false }) => {
    const linkProps = external
        ? { href: to, target: "_blank", rel: "noopener noreferrer" }
        : { to: to };

    const Component = external ? 'a' : Link;

    return (
        <Component
            {...linkProps}
            style={{
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                marginBottom: '12px',
                transition: 'color 0.3s ease'
            }}
            className="footer-link"
        >
            {children}
        </Component>
    );
};

const Footer = ({ mode = "light" }) => (
    <div style={{
        backgroundColor: '#222222',
        color: 'white',
        padding: '64px 48px 32px'
    }}>
        {/* Footer */}
        <div style={{
            backgroundColor: '#222222',
            color: 'white',
            padding: '64px 18px 32px'
        }}>
            <div style={{
                maxWidth: '1200px',
                margin: '0 auto'
            }}>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                    gap: '48px',
                    marginBottom: '48px'
                }}>
                    <div>
                        <div style={{
                            fontWeight: 700,
                            fontSize: '24px',
                            color: 'white',
                            marginBottom: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <EsinliOutline size={35} /> Esinli Capital
                        </div>
                        <p>Democratizing access to venture capital for accredited investors through professionally managed fund of funds.</p>
                    </div>
                    <div>
                        <FooterHeader>Company</FooterHeader>
                        <FooterLink to="/about">About us</FooterLink>
                        <FooterLink to="/contact">Contact us</FooterLink>
                        <FooterLink to="/glossary">Glossary</FooterLink>
                        <FooterLink to="/knowledge-base">Knowledge Base</FooterLink>
                        <div style={{ marginTop: '24px' }}>
                            <a
                                href="https://www.linkedin.com/company/esinli"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="LinkedIn"
                                style={{ color: 'white' }}
                            >
                                <LinkedinOutline size={24} color="white" />
                            </a>
                        </div>
                    </div>
                    <div>
                        <FooterHeader>Contact</FooterHeader>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                            <li style={{ marginBottom: '12px' }}><a href="mailto:team@esinli.com" style={{ color: 'white', textDecoration: 'none' }}>team@esinli.com</a></li>
                            <li style={{ marginBottom: '12px' }}><a href="tel:+972525622639" style={{ color: 'white', textDecoration: 'none' }}>+972 (525) 622-639</a></li>
                            <li style={{ marginBottom: '12px' }}><a href="https://calendar.app.google/qMN7g4rv4iL4Qo9J7" style={{ color: 'white', textDecoration: 'none' }}>Schedule a Call</a></li>
                        </ul>
                    </div>
                </div>
                <div style={{
                    textAlign: 'center',
                    paddingTop: '32px',
                    borderTop: '1px solid #444444',
                    color: '#999999',
                    fontSize: '14px'
                }}>
                    <p>&copy; {new Date().getFullYear()} Esinli Capital. All rights reserved. Investment involves risk. Past performance is not a guarantee of future results.</p>
                </div>
            </div>
        </div>

    </div>
);

export default Footer;