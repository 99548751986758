import React from "react";
import EsinliOutline from "../icons/EsinliOutline";
import LinkedinOutline from "../icons/LinkedinOutline";

import {Link} from "gatsby";
import {List, Popup, Space} from "antd-mobile";

const Menu = ({menuOpen, setMenuOpen}) => {

    return (
        <Popup showCloseButton
               visible={menuOpen}
               position='right'
               onClose={() => setMenuOpen(false)}
               onMaskClick={() => setMenuOpen(false)}
               bodyStyle={{width: '100vw', maxWidth: 360}}>
            <div style={{paddingTop: 36}}>
                <div style={{padding: 18}}>
                    <Link to={'/'} style={{color: 'rgb(17, 24, 39)'}}>
                        <Space align="center">
                            <EsinliOutline size={20}/><strong style={{fontSize: 14}}>Esinli Capital</strong>
                        </Space>
                    </Link>
                </div>
                <div style={{padding: 18}}>
                    {/*<Link to="/id/account"*/}
                    {/*      className="adm-button adm-button-block adm-button-large adm-button-primary adm-button-shape-rounded">*/}
                    {/*    Start now*/}
                    {/*</Link>*/}

                    <Link to="/id/sign-in"
                          className="adm-button adm-button-block adm-button-large adm-button-primary adm-button-fill-none adm-button-shape-rounded">
                        Sign in
                    </Link>
                </div>

                {/*<div style={{padding: 18}}>*/}
                {/*    <List mode="" header="EXPLORE">*/}
                {/*        <Link to="/startup-ecosystems"*/}
                {/*              style={{textDecoration: 'none'}}*/}
                {/*              className="adm-list-item adm-plain-anchor">*/}
                {/*            <div className="adm-list-item-content">*/}
                {/*                <div className="adm-list-item-content-main">Startup ecosystems</div>*/}
                {/*            </div>*/}
                {/*        </Link>*/}
                {/*        /!*<Link to="/startup-ecosystems"*!/*/}
                {/*        /!*      style={{textDecoration: 'none'}}*!/*/}
                {/*        /!*      className="adm-list-item adm-plain-anchor">*!/*/}
                {/*        /!*    <div className="adm-list-item-content">*!/*/}
                {/*        /!*        <div className="adm-list-item-content-main">Startup ecosystems</div>*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*</Link>*!/*/}
                {/*    </List>*/}
                {/*</div>*/}
                <div style={{padding: 18}}>
                    <List mode="" header="LEARN">
                        <Link to="/glossary"
                              style={{textDecoration: 'none'}}
                              className="adm-list-item adm-plain-anchor">
                            <div className="adm-list-item-content">
                                <div className="adm-list-item-content-main">Glossary</div>
                            </div>
                        </Link>
                    </List>
                </div>
                <div style={{padding: 18}}>
                    <List mode="" header="COMPANY">
                        <Link to="/about"
                              style={{textDecoration: 'none'}}
                              className="adm-list-item adm-plain-anchor">
                            <div className="adm-list-item-content">
                                <div className="adm-list-item-content-main">About us</div>
                            </div>
                        </Link>
                        <Link to="/contact"
                              style={{textDecoration: 'none'}}
                              className="adm-list-item adm-plain-anchor">
                            <div className="adm-list-item-content">
                                <div className="adm-list-item-content-main">Contact us</div>
                            </div>
                        </Link>
                    </List>
                </div>

                <div style={{padding: 18}}>
                    <List mode="card">
                        <a target="_blank"
                           aria-label="linkedin"
                           rel="noopener noreferrer"
                           className="adm-list-item adm-plain-anchor"
                           href='https://www.linkedin.com/company/esinli'>
                            <div className="adm-list-item-content">
                                <div className="adm-list-item-content-main">
                                    <LinkedinOutline size={24} color="#6b6b6c"/>
                                </div>
                            </div>
                        </a>
                    </List>
                </div>
            </div>
        </Popup>
    )
}

export default Menu;
