import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
// import CookieNotification from "./CookieNotification";

const Layout = ({children}) => {

    return (
        <>
            <NavBar/>
            {/*<CookieNotification/>*/}
            <main style={{}}>{children}</main>
            <Footer/>
        </>
    );
};

export default Layout;
